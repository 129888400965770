import z from "zod";
import merge from "deepmerge";

import STAGING_CONFIG from "./config.staging.mjs";
import PRODUCTION_CONFIG from "./config.production.mjs";

import { validate } from "./src/lib/validation.mjs";

/**
 * @typedef {import("./src/types/config.ts").Config} Config
 */

const activeEnv = validate(process.env["GATSBY_ACTIVE_ENV"], {
  label: "GATSBY_ACTIVE_ENV",
  schema: z.enum(["staging", "production"]).optional(),
});

/** @type {Record<NonNullable<activeEnv>, import("type-fest").PartialDeep<Config>>} */
const configMap = {
  staging: STAGING_CONFIG,
  production: PRODUCTION_CONFIG,
};

const envConfig = activeEnv && configMap[activeEnv];

const defaultShopName = validate(process.env["GATSBY_SHOPIFY_DEFAULT_SHOP"], {
  label: "GATSBY_SHOPIFY_DEFAULT_SHOP",
  schema: z.string(),
});
const sanityDataset = validate(process.env["GATSBY_SANITY_DEFAULT_DATASET"], {
  label: "GATSBY_SANITY_DEFAULT_DATASET",
  schema: z.string(),
});

/** @todo Source from server data instead, remove from this file. */
const shopifyApiVersion = "2023-07";

/** @todo Source from server data instead, remove from this file. */
const publicStorefrontToken =
  "bared-footwear-au-stage" === defaultShopName
    ? "881c82b6d2d31972c6e1bcafacb21983"
    : "9a0e203b14e7c2928886602a003d0db2";

/** @type {Config} */
const baseConfig = {
  localisation: [
    {
      allowedCountryCodes: ["US"],
      baseRoute: "/us",
      countryCodeCurrencyMap: {
        US: "USD",
      },
      defaultCountryCode: "US",
      defaultCurrency: "USD",
      isDefault: true,
      languageCode: "en-US",
      hrefLang: "en-us",
      enabled: true,
    },
    {
      allowedCountryCodes: ["AU"],
      baseRoute: "/au",
      countryCodeCurrencyMap: {
        AU: "AUD",
      },
      defaultCountryCode: "AU",
      defaultCurrency: "AUD",
      languageCode: "en-AU",
      hrefLang: "en-au",
      enabled: true,
    },
    {
      allowedCountryCodes: ["GB"],
      baseRoute: "/uk",
      countryCodeCurrencyMap: {
        GB: "GBP",
      },
      defaultCountryCode: "GB",
      defaultCurrency: "GBP",
      languageCode: "en-GB",
      hrefLang: "en-gb",
      enabled: true,
    },
    {
      allowedCountryCodes: ["NZ"],
      baseRoute: "/nz",
      countryCodeCurrencyMap: {
        NZ: "NZD",
      },
      defaultCountryCode: "NZ",
      defaultCurrency: "NZD",
      languageCode: "en-NZ",
      hrefLang: "en-nz",
      enabled: true,
    },
    {
      allowedCountryCodes: ["HK"],
      baseRoute: "/hk",
      countryCodeCurrencyMap: {
        HK: "HKD",
      },
      defaultCountryCode: "HK",
      defaultCurrency: "HKD",
      languageCode: "en-HK",
      hrefLang: "en-hk",
      enabled: true,
    },
    {
      allowedCountryCodes: ["SG"],
      baseRoute: "/sg",
      countryCodeCurrencyMap: {
        SG: "SGD",
      },
      defaultCountryCode: "SG",
      defaultCurrency: "SGD",
      languageCode: "en-SG",
      hrefLang: "en-sg",
      enabled: true,
    },
  ],
  stores: {
    "bared-footwear-au": {
      checkoutCurrency: "AUD",
      sanityDataset: "production",
      shopName: "bared-footwear-au",
      shopApiVersion: "2024-01",
      shopDomain: "checkout.baredfootwear.com",
      searchIndex: "bared-footwear-au-search",
      currencyMapping: {
        AU: "AUD",
        NZ: "NZD",
      },
    },
    "bared-footwear-au-stage": {
      checkoutCurrency: "AUD",
      sanityDataset: "staging",
      shopName: "bared-footwear-au-stage",
      shopApiVersion: "2024-01",
      searchIndex: "bared-footwear-search-stage",
      shopDomain: "bared-footwear-au-stage.myshopify.com",
      currencyMapping: {
        AU: "AUD",
        NZ: "NZD",
      },
    },
  },
  app: {
    themeColor: "#000000",
    title: "Bared Footwear",
    logo: "static/logo.svg",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    url: "https://localhost:8000",
    themeIcon: "static/favicon.png",
    description:
      "Look good, feel better. Designed by a podiatrist, Bared Footwear offers seriously stylish shoes for sneaker comfort, everyday. Unbeatable customer service. Free delivery. Free returns.",
  },
  services: {
    shopify: {
      defaultShopName,
      apiVersion: shopifyApiVersion,
      accessToken: publicStorefrontToken,
    },
    sanity: {
      projectId: "z60zyrm2",
      dataset: sanityDataset,
      apiVersion: "2023-06-01",
      /**
       * This token should be created only with VIEWER permissions. It is used to preview content
       * using draft documents with the `previewDrafts` perspective.
       *
       * It does not need to be secret, as it only provides access to drafts, which are already exposed
       * via the preview endpoints. On the balance of convenience vs security, it's better to define
       * the token here rather than every developer needing to create their own token, or copy it.
       *
       * This token is called "Gatsby Viewer Token" in the Sanity API tokens page.
       *
       * @see https://www.sanity.io/organizations/oqaUpjZKw/project/z60zyrm2/api#tokens
       */
      viewerToken:
        "skQFZsAsFnjJyZzQVWWRSr4mVi8h8bif1Odgn9MTJRLWAX3pfj2h24W4C40J0bXIwrJaZnqqElbfSKqIGLJeG17ZvPaXMT9Cmu1BvbCQikbSPsaeSxOKIGLBmXj1jaAzUz5D1X0kE8WvTBzgeY1hMwlSRl322VLpwk6CLgojmAgYJk6sFgMy",
    },
    firebase: {
      region: "us-central1",
      messagingSenderId: "37454649381",
      projectId: "bared-footwear-website",
      appId: "1:37454649381:web:fc1907e0d14d1516e787cc",
      apiKey: "AIzaSyCXT9Rsvr_kNvhhIqPpAeG-EGLLci8iGy8",
      storageBucket: "bared-footwear-website.appspot.com",
      authDomain: "bared-footwear-website.firebaseapp.com",
      databaseURL: "https://bared-footwear-website.firebaseio.com",
    },
    location: {
      forceRegion: true,
      countryFieldKey: "country",
      serviceUrl:
        "https://ipapi.co/json/?key=c9v2ZXiODyYLPDlRFR1eLtgFE0Zm5A4x8XLVMXZ4XlJyLcyV80",
    },
    functions: {
      endpoint: "http://localhost:8000/api",
    },
    reactify: {
      pageSize: 16,
      searchFieldKey: "q",
      filtersHandle: "sandbox",
    },
    googleMaps: {
      apiKey: "AIzaSyCon5-dT-0cf5G6npFYPB0MsJmvbTQpFkc",
    },
    reviews_io: {
      store: "bared.",
      snippet: {
        name: "ruk_rating_snippet",
        color: "#ba5c27",
      },
    },
    facebook: {
      trackingId: "1478745715775284",
    },
    klaviyo: {
      public_key: "JnNaYr",
    },
    cloudflare: {
      turnstileSiteKey: "0x4AAAAAAAD-iklfJyF74l3K",
    },
    zendesk: {
      userEmail: "techlogin@bared.com.au",
      subdomain: "baredfootwearhelp",
    },
  },
  settings: {
    keys: {
      global: {
        accessory_line_item_link: "_parent_variant",
        accessory_removed: "_accessory_removed",
        attributes_click_and_collect: "_click_and_collect",
        attributes_collect_location_title: "_collect_location_name",
        attributes_collect_location: "_collect_location_id",
        attributes_fitting_option: "foot fitting pack",
        attributes_note: "cart note",
        cart_added_at: "_addedAt",
        cart_aisle: "_aisle",
        cart_exchange_rate: "_exchange_rate",
        cart_finalsale: "_finalsale",
        cart_preorder: "_preorder",
        country_klavio_tag: "country",
        customer: "headless:customer",
        localWishlist: "headless:localWishlist",
        metafields_key_hpDetails: "healthProfessionalDetails",
        metafields_key_instoreOrders: "inStoreOrderIds",
        metafields_key_rbhpDetails: "referringByHealthProDetails",
        metafields_namespace: "lightspeedConnector",
        product_images_cover_image: "_cover",
        queue_collection: "queue",
        queue_id_not_set: "__NOT_SET",
        queue_id: "queue:id",
        shopify: "headless:shopify",
        tag_birthday: "birthday",
        tag_gift_card_price_max: "gift_card_max_price",
        tag_gift_card_price_min: "gift_card_min_price",
        tag_reference: "customer_type",
        vip_tag: "loyalty:VIP",
        website: "website",
        wishlist: "headless:wishlist",
        wishlistKey: "headless:wishlistKey",
      },
      local: {
        announcement: "headless:announcement",
        checkout: "headless:checkout",
        collection_filters: "headless:filters",
        collection_last: "headless:collection_last",
        collection_scroll_position: "headless:collection_scroll_position",
        country: "headless:country",
        discounts: "headless:discounts",
        location: "headless:location",
        place_id: "headless:place_id",
        stores: "headless:stores",
        subscribe: "headless:subscribe",
      },
    },
    htmlAttributes: {
      lang: "en",
    },
    routes: {
      PAGE: "",
      FLEXIBLE: "",
      HOMEPAGE: "/",
      CART: "/cart",
      SEARCH: "/search",
      SAVED: "/wishlist",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      EDIT: "/account/edit",
      "IN-STORE": "/account/in-store",
      ADDRESSES: "/account/addresses",
      LOYALTY: "/account/loyalty",
      PREFERENCES: "/account/preferences",
      GIFTCARD: "/account/giftcard",
      WISHLIST: "/account/wishlist",
      AUTH: "/account/login/auth",
      LOGIN_LOADING: "/account/login/loading",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLES: "/articles",
      ARTICLE: "/articles",
      RETURN: "/returns-portal",
    },
    returnSlugs: {
      "try-additional-shoes": "SEND_SHOES",
      "sale-shoes-store-credit": "SALE_PURCHASES",
      "sale-shoes-try-additional-shoes": "SEND_SALE_SHOES",
      "normal-return": "NORMAL_RETURN",
    },
    pagination: {
      products: 48,
      productsMobile: 12,
      productUpsells: 10,
    },
    params: {
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      wishlist: "id",
    },
    clientPaths: [
      "/account/reset/*",
      "/account/activate/*",
      "/account/orders/*",
    ],
    sitemapExclusions: [
      "/dev-404-page",
      "/404",
      "/404.html",
      "/preview",
      "/account",
      "/account/activate",
      "/account/addresses",
      "/account/details",
      "/account/forgot",
      "/account/login",
      "/account/orders",
      "/account/reset",
      "/account/wishlist",
      "/search",
      "/wishlist",
      "/cart",
      "/feedback",
    ],
    socialMedia: {
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
      },
    },
    returnType: {
      SALE_PURCHASES: "SALE_PURCHASES",
      SEND_SALE_SHOES: "SEND_SALE_SHOES",
      SEND_SHOES: "SEND_SHOES",
      NORMAL_RETURN: "NORMAL_RETURN",
    },
  },
};

export default /** @type {Config} */ (merge(baseConfig, envConfig ?? {}));
