import React, { FC } from "react";
import { ComponentProps } from "../../../types/components";
import { styles } from "./CartDeliveryOptions";

export type CartDeliveryOptionsProps = ComponentProps & {
  layout: keyof typeof styles;
};

export const withCartDeliveryOptions =
  (Component: FC<CartDeliveryOptionsProps>) =>
  ({ name = "CartDeliveryOptions ", layout }): JSX.Element => {
    Component.displayName = name;
    return <Component layout={layout} />;
  };
