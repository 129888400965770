import {
  NavigationCollectionWithIcon,
  NavigationItems,
  ResponsiveImages,
  SanityKeyed,
} from "@ts/sanity";
import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import { useApp } from "./useApp";
import { useLocalisation } from "./useLocalisation";
import { useRoutes } from "./useRoutes";

export type Navigation =
  | NavigationItems
  | SanityKeyed<NavigationCollectionWithIcon>[];

export type NavigationMenu = {
  items: Navigation;
  slug: {
    current: string;
  };
};

export type NormalisedNavigation = {
  type?: string;
  title?: string;
  url?: string;
  featuredTitle?: string;
  items?: NormalisedNavigation[];
  shopEditTitle?: string;
  shopEditItems?: NormalisedNavigation[];
  collectionTitle?: string;
  contents?: NormalisedNavigation[];
  icon?: string;
  highlightLink?: boolean;
  images?: ResponsiveImages[];
};

export type UseNavigation = {
  footer: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
  header: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
  sale: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
  terms: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
  topBar: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
  vipSale: {
    items: NormalisedNavigation[];
    flattened?: Record<string, string[]>;
  };
};

type RawNavigation = {
  footer: NavigationMenu;
  header: NavigationMenu;
  sale: NavigationMenu;
  terms: NavigationMenu;
  topBar: NavigationMenu;
  vipSale: NavigationMenu;
};

export const useNavigation = (): UseNavigation => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp();
  const { linkResolver } = useRoutes();
  const { findNodeByLocale } = useLocalisation();
  const { menus } = useStaticQuery(graphql`
    query Menus {
      menus: allSanitySettingMenus {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            header {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
            sale {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
            vipSale {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
            footer {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
            terms {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
            topBar {
              items {
                ...Link
                ...NavigationArticle
                ...NavigationFeat
                ...NavigationCollection
                ...NavigationPage
                ...NavigationSub
              }
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const { header, sale, vipSale, footer, topBar, terms } =
    findNodeByLocale<RawNavigation>(menus);

  const mapItems = (items: Navigation): NormalisedNavigation[] => {
    return items?.map((item) => {
      const type = item?._type?.replace("navigation", "")?.toLowerCase();

      switch (type) {
        case "feat":
          return {
            type,
            title: linkResolver(item.menuLink).title,
            url: linkResolver(item.menuLink).url,
            featuredTitle: item?.featuredTitle,
            items: item?.items?.length > 0 ? mapItems(item.items) : [],
            shopEditTitle: item?.shopEditTitle,
            shopEditItems:
              item?.shopEditItems?.length > 0
                ? mapItems(item.shopEditItems)
                : [],
            collectionTitle: item?.collectionTitle,
            contents: item?.contents?.length > 0 ? mapItems(item.contents) : [],
            images: item?.images?.length > 0 ? item.images : [],
          };
        case "collectionwithicon":
          return {
            type,
            title: item?.title,
            url: mapUrl(item.collection, "collection"),
            icon: item?.icon,
          };
        default:
          return {
            type,
            highlightLink: item?.highlightLink,
            title: item?.title,
            url: mapUrl(item[type], type),
            items: item?.items?.length > 0 ? mapItems(item.items) : [],
          };
      }
    });
  };

  const mapUrl = (item, type: string) => {
    switch (type) {
      case "page":
        return `${routes[item?._type?.toUpperCase().replace("PAGE", "").replace("ACCOUNT", "")] || "/"}${item?.slug?.current || ""}`;
      case "product":
      case "collection":
        return `${routes[type.toUpperCase()] || "/"}/${item?.shopify?.shopifyHandle || ""}`;
      case "link":
        return `${item}`;
      default:
        return;
    }
  };

  const flatten = (items, level: number = 0) => {
    const parentProps = ["items", "contents", "shopEditItems"];

    if (level === 0) {
      return items.reduce((prev, curr) => {
        const mergedItems = parentProps
          .map((prop) => curr[prop])
          .reduce((prev, curr) => [...prev, ...(curr ?? [])], []);
        return {
          ...prev,
          [curr.url]: flatten(mergedItems, level + 1),
        };
      }, {});
    }

    return items
      .map((item) => {
        if (item?.items?.length > 0) {
          return flatten(item.items, level + 1);
        }

        return item?.url;
      })
      .filter((item) => !!item);
  };

  const footerItems = mapItems(footer?.items);
  const footerFlattened = flatten(footerItems);
  const headerItems = mapItems(header?.items);
  const headerFlattened = flatten(headerItems);
  const saleItems = mapItems(sale?.items);
  const saleItemsFlattened = flatten(saleItems);
  const termsItems = mapItems(terms?.items);
  const termsFlattened = flatten(termsItems);
  const topBarItems = mapItems(topBar?.items);
  const topBarFlattened = flatten(topBarItems);
  const vipSaleItems = mapItems(vipSale?.items);
  const vipSaleItemsFlattened = flatten(vipSaleItems);

  return useMemo(
    () => ({
      footer: {
        items: footerItems,
        flattened: footerFlattened,
      },
      header: {
        items: headerItems,
        flattened: headerFlattened,
      },
      sale: {
        items: saleItems,
        flattened: saleItemsFlattened,
      },
      terms: {
        items: termsItems,
        flattened: termsFlattened,
      },
      topBar: {
        items: topBarItems,
        flattened: topBarFlattened,
      },
      vipSale: {
        items: vipSaleItems,
        flattened: vipSaleItemsFlattened,
      },
    }),
    [
      footerItems,
      headerItems,
      saleItems,
      termsItems,
      topBarItems,
      vipSaleItems,
    ],
  );
};
