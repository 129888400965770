/** @todo Use path alias when gatsby dead  */
import { sites } from "../config/sites";

/**
 * Configuration for a single "site".
 */
export interface Site {
  /**
   * Unique handle for site.
   * @example "au"
   */
  handle: string;
  /**
   * URL prefix at which site is hosted.
   * @example "au"
   */
  urlPrefix: string;
  /**
   * Local identifier.
   * @example "en-AU"
   */
  locale: string;
  /**
   * Currency code to show on site.
   * @example "AUD"
   */
  currencyCode: string;
  /**
   * Array of country codes which should be directed to this site.
   *
   * You may include a "ROW" country code here, which will be used as a fallback site for
   * country codes which do not have a dedicated site.
   *
   * @example ["AU", "NZ"]
   */
  countryCodes: string[];
  /**
   * Product tag which identifies products to be included in this site.
   */
  productTag: string;
  /**
   * The "primary" country code associated with the site.
   *
   * This is used for backwards compatibility purposes in Shopify queries and other logic
   * which uses the "contextCountry" variable.
   *
   * Rather than using the actual viewers country, this uses the country associated with
   * the site so for example all queries for the AU site would set "AU" as the country
   * rather than the viewers actual country.
   *
   * @example "AU"
   * @deprecated Do not use this for anything new. Use the viewer country instead.
   */
  __primaryCountryCode: string;
}

/**
 * Return site for the given country code.
 */
export function resolveSiteFromCountryCode({
  countryCode,
}: {
  countryCode: string;
}) {
  const site = sites.find((site) => site.countryCodes.includes(countryCode));
  if (site) return site;

  const rowSite = sites.find((site) => site.countryCodes.includes("ROW"));
  if (rowSite) return rowSite;

  throw new Error(`Could not resolve site for country code ${countryCode}.`);
}

/**
 * Return site for the given handle.
 */
export function resolveSiteFromHandle({ handle }: { handle: string }) {
  const site = sites.find((site) => site.handle === handle);
  if (site) return site;
  throw new Error(`Could not resolve site for handle ${handle}.`);
}

/**
 * Return site for the given URL prefix.
 */
export function resolveSiteFromUrlPrefix({ urlPrefix }: { urlPrefix: string }) {
  const site = sites.find((site) => site.urlPrefix === urlPrefix);
  if (site) return site;
  throw new Error(`Could not resolve site for URL prefix ${urlPrefix}.`);
}

/**
 * Map a site to a legacy "locale" object.
 */
export function mapSiteToLegacyLocale(site: Site): LegacyLocale {
  return {
    allowedCountryCodes: site.countryCodes,
    // If the url prefix is empty, then base route is mapped to "" NOT "/" ¯\_(ツ)_/¯
    baseRoute: site.urlPrefix ? `/${site.urlPrefix}` : "",
    countryCodeCurrencyMap: {
      [site.__primaryCountryCode]: site.currencyCode,
    },
    defaultCountryCode: site.__primaryCountryCode,
    defaultCurrency: site.currencyCode,
    enabled: true,
    hrefLang: site.locale,
    languageCode: site.locale,
    isDefault: "us" === site.handle,
  };
}

/**
 * Legacy "locale" object which used to be included in config.
 * We map the new "Site" objects to this for backwards compatibility.
 */
export interface LegacyLocale {
  /** @example ["AU"] */
  allowedCountryCodes: string[];
  /** @example "/au" */
  baseRoute: string;
  /** @example {AU: "AUD"} */
  countryCodeCurrencyMap: Record<string, string>;
  /** @example "AU" */
  defaultCountryCode: string;
  /** @example "AUD" */
  defaultCurrency: string;
  /** @example true */
  enabled: boolean;
  /** @example "en-au" */
  hrefLang: string;
  /** @example "en-AU" */
  languageCode: string;
  /** @example true */
  isDefault: boolean;
}
