/**
 * Use Firestore to implement wishlist persistent.
 * In order to manage the wishlist properly, call setWishlist and setIsWishlistInitialized at login and logout
 */

import { useApp } from "@hooks/useApp";
import { useCore } from "@hooks/useCore";
import { useCustomerContext } from "@hooks/useCustomer";
import { useHelper } from "@hooks/useHelper";
import { useLocalisationContext } from "@hooks/useLocalisation";
import { Product } from "@ts/shopify-storefront";
import React, {
  Context,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFirebaseContext } from "../global/firebase";

import { useStorefrontLegacyQuery } from "~/hooks/useStorefrontLegacy";

export enum WishlistErrorType {
  WISHLIST = "WISHLIST",
  FRIEND_WISHLIST = "FRIEND_WISHLIST",
  SHARED_WISHLIST = "SHARED_WISHLIST",
}

export type Wishlist = {
  firstName: string;
  id: string;
  isPublic: boolean;
  lastName: string;
  wishlist: WishlistProduct[];
};

export type WishlistProduct = Partial<Product> & {
  selectedId?: string;
  selectedVariant?: string;
  selectedTitle?: string;
  wishlistPrice?: number;
  wishlistImage?: string;
};

export type WishlistUnfilteredContextValues = {
  errorType?: WishlistErrorType;
  friendEmail?: string;
  friendFirstName?: string;
  friendLoading?: boolean;
  friendWishlist?: WishlistProduct[];
  friendWishlistUrl?: string;
  isWishlistPublic?: boolean;
  loading?: boolean;
  setFriendEmail?: Dispatch<SetStateAction<string>>;
  setFriendFirstName?: Dispatch<SetStateAction<string>>;
  setFriendWishlist?: Dispatch<SetStateAction<WishlistProduct[]>>;
  setFriendWishlistUrl?: Dispatch<SetStateAction<string>>;
  setIsWishlistInitialized?: Dispatch<SetStateAction<boolean>>;
  setIsWishlistPublic?: Dispatch<SetStateAction<boolean>>;
  setSharedParam?: Dispatch<SetStateAction<string>>;
  setWishlist?: Dispatch<SetStateAction<WishlistProduct[]>>;
  sharedFirstName?: string;
  sharedWishlist?: WishlistProduct[];
  sharedLoading?: boolean;
  wishlist?: WishlistProduct[];
};

export type WishlistContextValues = WishlistUnfilteredContextValues & {
  count?: number;
  error?: string;
  loading?: boolean;
  products?: WishlistProduct[];
  setError?: Dispatch<SetStateAction<string>>;
};

export const WishlistUnfilteredContext: Context<WishlistUnfilteredContextValues> =
  React.createContext<WishlistUnfilteredContextValues>({});
export const WishlistContext: Context<WishlistContextValues> =
  React.createContext<WishlistContextValues>({});

export const WishlistProvider = ({ children }) => {
  const {
    helpers: { storage, decodeBase64 },
  } = useCore();

  const {
    config: {
      settings: { keys },
    },
  } = useApp();

  const { db } = useFirebaseContext();
  const { customer } = useCustomerContext();
  const { isJson, removeDuplicateObjectInArray, getShareWishlistURL } =
    useHelper();
  const customerToken = storage.get(keys?.customer);
  const [wishlist, setWishlist] = useState([]);
  const [isWishlistPublic, setIsWishlistPublic] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);
  const [error, setError] = useState<string>();
  const [errorType, setErrorType] = useState<WishlistErrorType>();
  const localWishlist = storage.get(keys?.localWishlist) ?? [];
  const [friendFirstName, setFriendFirstName] = useState("");
  const [friendEmail, setFriendEmail] = useState("");
  const [friendWishlist, setFriendWishlist] = useState([]);
  const [friendWishlistUrl, setFriendWishlistUrl] = useState(null);
  const [sharedParam, setSharedParam] = useState(null);
  const [sharedFirstName, setSharedFirstName] = useState(null);
  const [sharedWishlist, setSharedWishlist] = useState([]);
  const [unsubscribeListener, setUnsubscribeListener] = useState(null);

  /**
   *  Listen wishlist changes in other tabs or browser
   *  Keep "wishlist" state always up-to-date
   */
  useEffect(() => {
    if (db && customer?.email) {
      let unsubscribe = db
        .collection("wishlistNew")
        .doc(customer.email)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              const dbWishlist: WishlistProduct[] = doc.data().wishlist;
              const isWishlistPublic: boolean = doc.data().isPublic ?? false;
              setWishlist(dbWishlist);
              setIsWishlistPublic(isWishlistPublic);
            }
          },
          (err) => {
            setError("Error retrieving wishlist, please try again");
            setErrorType(WishlistErrorType.WISHLIST);
            console.error(err);
          },
        );

      // For detaching the listener after customer logged out
      setUnsubscribeListener({ run: () => unsubscribe() });
    } else {
      unsubscribeListener?.run();
      setUnsubscribeListener(null);
    }
  }, [db, customer?.email]);

  /**
   *  if the friend's email is correct,
   *  and their wishlist is made public,
   *  initialize the friendWishlist to the wishlist associated with that email
   */
  useEffect(() => {
    setFriendWishlist([]);
    if (db && friendEmail) {
      db.collection("wishlistNew")
        .doc(friendEmail)
        .get()
        .then(
          (doc) => {
            if (doc.exists) {
              const data = doc.data();
              const dbWishlist = data.wishlist;
              if (data.isPublic && dbWishlist?.length > 0) {
                const url = getShareWishlistURL(
                  friendEmail,
                  data.firstName,
                  true,
                );
                setFriendFirstName(data.firstName);
                setFriendWishlist(dbWishlist);
                setFriendWishlistUrl(url);
                setError(undefined);
                setErrorType(undefined);
              } else {
                setError(`Public wishlist not found for ${friendEmail}`);
                setErrorType(WishlistErrorType.FRIEND_WISHLIST);
                setFriendWishlist([]);
              }
            } else {
              setError(`Wishlist not found for ${friendEmail}`);
              setErrorType(WishlistErrorType.FRIEND_WISHLIST);
              setFriendWishlist([]);
            }
          },
          (err) => {
            setError(
              `Error retrieving wishlist for ${friendEmail}, please try again`,
            );
            setErrorType(WishlistErrorType.FRIEND_WISHLIST);
            setFriendWishlist([]);
            console.error(err);
          },
        );
    }
  }, [db, friendEmail]);

  /**
   *  if there is no customerToken,
   *  initialize the wishlist from the localWishlist
   *  ONLY RUN ONCE AT FIRST RENDER
   */
  useEffect(() => {
    if (!customerToken && !isInitialised) {
      setWishlist(localWishlist);
      setIsInitialised(true);
    }
  }, [isInitialised]);

  /**
   *  if the customer logged in,
   *  wait until customer data available,
   *  initialize the wishlist by the customer wishlist doc from FireStore
   */
  useEffect(() => {
    if (db && !isInitialised && customer?.email) {
      db.collection("wishlistNew")
        .doc(customer.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const dbWishlist = doc.data().wishlist;
            const isWishlistPublic = doc.data().isPublic ?? false;
            const newWishlist = removeDuplicateObjectInArray(
              [...localWishlist, ...dbWishlist],
              "handle",
            );
            setWishlist(newWishlist);
            setIsWishlistPublic(isWishlistPublic);
            setIsInitialised(true);
            storage.set(keys?.localWishlist, []);
          } else {
            setWishlist(localWishlist);
            setIsInitialised(true);
            storage.set(keys?.localWishlist, []);
          }
        });
    }
  }, [db, isInitialised, customer?.email]);

  /**
   *  Get shared wishlist
   */
  useEffect(() => {
    if (db && sharedParam) {
      const decodedParams = decodeBase64(sharedParam);

      if (!isJson(decodedParams)) {
        setError(`Shared wishlist does not exist`);
        setErrorType(WishlistErrorType.SHARED_WISHLIST);
        return;
      }

      const formattedData = JSON.parse(decodedParams);
      const { email, name } = formattedData;

      if (!email) {
        setError(`Shared wishlist does not exist`);
        setErrorType(WishlistErrorType.SHARED_WISHLIST);
        return;
      }

      db.collection("wishlistNew")
        .doc(formattedData.email)
        .get()
        .then(
          (doc) => {
            if (doc.exists) {
              const data = doc.data();
              if (data?.isPublic) {
                setSharedWishlist(data?.wishlist ?? []);
              } else {
                setError("Shared wishlist is not public");
                setErrorType(WishlistErrorType.SHARED_WISHLIST);
              }
              setSharedFirstName(name);
            } else {
              setError("Shared wishlist does not exist");
              setErrorType(WishlistErrorType.SHARED_WISHLIST);
            }
          },
          (err) => {
            setError(`Error retrieving wishlist for ${name}, please try again`);
            setErrorType(WishlistErrorType.SHARED_WISHLIST);
            setSharedWishlist([]);
            console.error(err);
          },
        );
    }
  }, [db, sharedParam]);

  /**
   *  if there is customer and wishlist updates,
   *  write wishlist into the current customer's wishlist doc
   */
  useEffect(() => {
    if (db && customer?.email && isInitialised) {
      db.collection("wishlistNew")
        .doc(customer.email)
        .set(
          {
            wishlist: [...wishlist],
          },
          {
            merge: true,
          },
        );
    }
  }, [db, customer, wishlist, isInitialised]);

  /**
   *  if there is no customer and wishlist updates,
   *  update wishlist to localWishlist
   */
  useEffect(() => {
    if (!customerToken && isInitialised) {
      storage.set(keys?.localWishlist, wishlist);
    }
  }, [wishlist, isInitialised]);

  useEffect(() => {
    if (db && customer?.email && isInitialised) {
      db.collection("wishlistNew")
        .doc(customer.email)
        .set(
          {
            isPublic: isWishlistPublic,
            firstName: customer?.firstName ?? "EMPTY",
          },
          {
            merge: true,
          },
        );
    }
  }, [db, customer, isInitialised, isWishlistPublic]);

  const values = useMemo(
    () => ({
      error,
      errorType,
      friendEmail,
      friendFirstName,
      friendWishlist,
      friendWishlistUrl,
      isWishlistPublic,
      setFriendEmail,
      setFriendFirstName,
      setFriendWishlist,
      setFriendWishlistUrl,
      setIsWishlistInitialized: setIsInitialised,
      setIsWishlistPublic,
      setSharedParam,
      setWishlist,
      sharedFirstName,
      sharedWishlist,
      wishlist,
    }),
    [
      error,
      errorType,
      friendEmail,
      friendFirstName,
      friendWishlist,
      friendWishlistUrl,
      isWishlistPublic,
      setFriendEmail,
      setFriendFirstName,
      setFriendWishlist,
      setFriendWishlistUrl,
      setIsInitialised,
      setIsWishlistPublic,
      setSharedParam,
      setWishlist,
      sharedFirstName,
      sharedWishlist,
      wishlist,
    ],
  );

  return (
    <WishlistUnfilteredContext.Provider value={values}>
      <WishlistFilteredProvider {...values}>
        {children}
      </WishlistFilteredProvider>
    </WishlistUnfilteredContext.Provider>
  );
};

export const WishlistFilteredProvider = ({
  children,
  wishlist,
  friendWishlist,
  sharedWishlist,
  errorType,
  ...rest
}) => {
  const { currentLocale } = useLocalisationContext();
  const { defaultCountryCode } = currentLocale || {};
  const countryTag = defaultCountryCode;
  const [products, setProducts] = useState(wishlist);
  const [batchesLoaded, setBatchesLoaded] = useState(0);
  const [productHandlesBatch, setProductHandlesBatch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstPass, setIsFirstPass] = useState(false);

  const [friendProducts, setFriendProducts] = useState([]);
  const [friendBatchesLoaded, setFriendBatchesLoaded] = useState(0);
  const [friendProductHandlesBatch, setFriendProductHandlesBatch] = useState(
    [],
  );
  const [friendIsLoading, setFriendIsLoading] = useState(false);

  const [sharedProducts, setSharedProducts] = useState([]);
  const [sharedBatchesLoaded, setSharedBatchesLoaded] = useState(0);
  const [sharedProductHandlesBatch, setSharedProductHandlesBatch] = useState(
    [],
  );
  const [sharedIsLoading, setSharedIsLoading] = useState(false);

  const batchSize = 20;

  const filterWishlistProducts = (
    source: WishlistProduct[],
    destination: WishlistProduct[],
    data,
  ) => {
    const filteredWishlist = source
      ?.filter((product) => {
        const key = `product${product.handle.replace(/-/g, "")}`;
        const liveProduct = data[key];
        return (
          liveProduct &&
          !destination.find(({ handle }) => product.handle === handle) &&
          liveProduct.tags.includes(`country:${countryTag}`)
        );
      })
      .map((product) => {
        const key = `product${product.handle.replace(/-/g, "")}`;
        return { ...data[key], selectedId: product.selectedId };
      });

    return filteredWishlist;
  };

  const handleLoad = useCallback(
    (
      batchesLoadedCallback: Dispatch<SetStateAction<number>>,
      productCallback: Dispatch<SetStateAction<WishlistProduct[]>>,
      sourceWishlist: WishlistProduct[],
      destinationWishlist: WishlistProduct[],
    ) =>
      (data) => {
        const filteredWishlistProducts = filterWishlistProducts(
          sourceWishlist,
          destinationWishlist,
          data,
        );
        productCallback((prevState) =>
          isFirstPass
            ? filteredWishlistProducts
            : [...prevState, ...filteredWishlistProducts],
        );
        batchesLoadedCallback((prevState) => prevState + 1);
        setIsFirstPass(false);
      },
    [
      setProducts,
      setBatchesLoaded,
      setFriendProducts,
      setFriendBatchesLoaded,
      filterWishlistProducts,
    ],
  );

  const updateWishlist = (
    wishlist: WishlistProduct[],
    batchHandleCallback: Dispatch<SetStateAction<string[][]>>,
    batchesLoadedCallback: Dispatch<SetStateAction<number>>,
    loadingCallback: Dispatch<SetStateAction<boolean>>,
    productCallback: Dispatch<SetStateAction<WishlistProduct[]>>,
    resetErrorType: WishlistErrorType,
  ) => {
    batchHandleCallback([]);
    batchesLoadedCallback(0);
    loadingCallback(true);

    if (resetErrorType === errorType) {
      productCallback([]);
      return loadingCallback(false);
    }

    for (let x = 0; x < wishlist?.length; x += batchSize) {
      const productHandles = wishlist
        .slice(x, x + batchSize)
        .map((product) => product.handle);
      batchHandleCallback((prevState) => [...prevState, productHandles]);
    }

    if (wishlist.length != products.length) {
      const filteredProducts = products.filter(({ handle }) =>
        wishlist.find((product) => {
          return product.handle === handle;
        }),
      );
      productCallback(filteredProducts);
    } else {
      loadingCallback(false);
    }
  };

  useEffect(() => {
    updateWishlist(
      wishlist,
      setProductHandlesBatch,
      setBatchesLoaded,
      setIsLoading,
      setProducts,
      WishlistErrorType.WISHLIST,
    );
  }, [wishlist]);

  useEffect(() => {
    updateWishlist(
      friendWishlist,
      setFriendProductHandlesBatch,
      setFriendBatchesLoaded,
      setFriendIsLoading,
      setFriendProducts,
      WishlistErrorType.FRIEND_WISHLIST,
    );
  }, [friendWishlist]);

  useEffect(() => {
    updateWishlist(
      sharedWishlist,
      setSharedProductHandlesBatch,
      setSharedBatchesLoaded,
      setSharedIsLoading,
      setSharedProducts,
      WishlistErrorType.SHARED_WISHLIST,
    );
  }, [sharedWishlist]);

  useEffect(() => {
    setIsLoading(batchesLoaded < productHandlesBatch.length);
  }, [batchesLoaded]);

  useEffect(() => {
    setFriendIsLoading(friendBatchesLoaded < friendProductHandlesBatch.length);
  }, [friendBatchesLoaded]);

  useEffect(() => {
    setSharedIsLoading(sharedBatchesLoaded < sharedProductHandlesBatch.length);
  }, [sharedBatchesLoaded]);

  const values = useMemo(
    () => ({
      wishlist: products,
      friendWishlist: friendProducts,
      sharedWishlist: sharedProducts,
      unfilteredWishlist: wishlist,
      loading: isLoading,
      friendLoading: friendIsLoading,
      sharedLoading: sharedIsLoading,
      count: products?.length,
      errorType,
      ...rest,
    }),
    [
      products,
      friendProducts,
      sharedProducts,
      wishlist,
      isLoading,
      friendIsLoading,
      sharedIsLoading,
      errorType,
      rest,
    ],
  );

  return (
    <WishlistContext.Provider value={values}>
      {productHandlesBatch?.map((handles, index) => {
        return (
          <WishlistBatchedQuery
            productHandles={handles}
            onLoad={handleLoad(
              setBatchesLoaded,
              setProducts,
              wishlist,
              products,
            )}
            key={`wishlist-query-${index}`}
          />
        );
      })}
      {friendProductHandlesBatch?.map((handles, index) => {
        return (
          <WishlistBatchedQuery
            productHandles={handles}
            onLoad={handleLoad(
              setFriendBatchesLoaded,
              setFriendProducts,
              friendWishlist,
              friendProducts,
            )}
            key={`friend-wishlist-query-${index}`}
          />
        );
      })}
      {sharedProductHandlesBatch?.map((handles, index) => {
        return (
          <WishlistBatchedQuery
            productHandles={handles}
            onLoad={handleLoad(
              setSharedBatchesLoaded,
              setSharedProducts,
              sharedWishlist,
              sharedProducts,
            )}
            key={`shared-query-${index}`}
          />
        );
      })}
      {children}
    </WishlistContext.Provider>
  );
};

const WishlistBatchedQuery = ({ productHandles, onLoad }) => {
  const {
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE_LIGHT },
    },
  } = useCore();
  const { contextCountry } = useLocalisationContext();

  const { data } = useStorefrontLegacyQuery(
    GET_PRODUCTS_BY_HANDLE_LIGHT(productHandles),
    {
      fetchPolicy: `cache-and-network`,
      variables: {
        countryCode: contextCountry,
        firstImages: 2,
        firstVariants: 50,
      },
    },
  );

  useEffect(() => {
    if (!data) return;
    onLoad(data);
  }, [data]);

  return null;
};
