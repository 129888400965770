import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { CartDeliveryOptions } from "@components/Cart/DeliveryOptions/CartDeliveryOptions";
import CartDonationItems from "@components/Cart/Donations/CartDonationItems";
import { CartLineItemAccessoryCard } from "@components/Cart/LineItems/CartLineItemAccessoryCard";
import CartLineItemCard from "@components/Cart/LineItems/CartLineItemCard";
import CartNotes from "@components/Cart/Notes/CartNotes";
import { CartReturnsPolicy } from "@components/Cart/Returns/CartReturnsPolicy";
import CartTotals from "@components/Cart/Totals/CartTotals";
import { CartUpsells } from "@components/Cart/Upsells/CartUpsells";
import { Icon } from "@components/Icon";
import { Link } from "@components/Link/Link";
import ModalsDrawer from "@components/Modals/ModalsDrawer";
import { StyledButton } from "@components/Styled/Button";
import { ProductProvider } from "@providers/localised/product";
import React from "react";
import tw, { styled } from "twin.macro";
import { CartDrawerOutputProps, withCartDrawer } from "./withCartDrawer";

const Wrapper = tw.div`
  overflow-x-hidden h-full pb-0
`;

const SubwrapperLineitems = tw.div`
  max-h-36 overflow-y-auto overflow-x-hidden
`;

const Subwrapper = tw.div`
  px-midi pt-midi 
`;

const Title = tw.h2`text-[18px] font-display leading-h2 my-0 text-left bg-primary bg-opacity-10 p-midi md:leading-h4`;

const ActionTitle = styled.p`
  ${tw`text-white p-midi bg-primary`}
  span {
    vertical-align: middle;
    margin-right: 6px;
  }
`;

const Lineitems = tw.div``;

const Totals = tw(CartTotals)`
  mb-mini
`;

const Checkout = withFocusRing(tw.a`
  flex items-center justify-center w-full text-white h-[56px] text-body bg-primary hover:bg-secondary mb-mini
`);

const ViewCart = withFocusRing(styled.button`
  ${tw`w-full leading-caption mb-maxi`}
  span {
    ${tw`border-b hover:text-secondary`}
  }
`);

const KeepShopping = tw(StyledButton)`
  w-full
`;

const PaymentIcons = tw.div`
  flex justify-between max-w-[330px] mx-auto py-2
`;

const StyledPaymentIcons = tw(Icon)`text-primary`;

const Donationitems = tw(CartDonationItems)`
  w-full mb-4
`;

const StyledIcon = styled(Icon)`
  ${tw`text-white`}
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const CartDrawer = withCartDrawer(
  ({
    activeCart,
    cartItemsQuantity,
    cartUrl,
    checkoutUrl,
    donationEnabled,
    donationProducts,
    emptyTitle,
    handleCartActive,
    handleCheckout,
    keepShopping,
    lineItemsAccessoryMap,
    lineItemsProducts,
    loading,
    title,
    totalItems,
  }: CartDrawerOutputProps) => {
    return (
      <ModalsDrawer
        active={
          typeof activeCart !== "boolean" ? activeCart?.activeCart : activeCart
        }
        setActive={handleCartActive}
        right={true}
        data-testid="cart-drawer"
      >
        <Wrapper>
          <Title>{cartItemsQuantity > 0 ? title : emptyTitle}</Title>
          {typeof activeCart !== "boolean" && activeCart.newItemAdded && (
            <ActionTitle>
              <StyledIcon name="success" size="xxs" />
              <span>Item Added to Cart</span>
            </ActionTitle>
          )}

          {cartItemsQuantity > 0 ? (
            <>
              <SubwrapperLineitems>
                <Lineitems>
                  {lineItemsProducts?.map((lineItem, index) => {
                    const { variant } = lineItem;
                    const { id } = variant;

                    if (!lineItemsAccessoryMap?.[id]) {
                      return (
                        <ProductProvider
                          stub={variant.product}
                          key={`cart-item-no-accessory-${lineItem.id}`}
                        >
                          <CartLineItemCard
                            data-testid="cart-item-no-accessory"
                            isLast={index === totalItems - 1}
                            lineItem={lineItem}
                            layout="drawer"
                          />
                        </ProductProvider>
                      );
                    }

                    return (
                      <>
                        <ProductProvider
                          stub={variant.product}
                          key={`cart-item-with-accessory-${lineItem.id}`}
                        >
                          <CartLineItemCard
                            data-testid="cart-item-with-accessory"
                            isLast={index === totalItems - 1}
                            lineItem={lineItem}
                            layout="drawer"
                          />
                        </ProductProvider>
                        {lineItemsAccessoryMap[id].map((lineItemAccessory) => (
                          <CartLineItemAccessoryCard
                            data-testid="cart-item-accessory"
                            key={`cart-item-accessory-${lineItemAccessory.id}`}
                            lineItem={lineItemAccessory}
                            layout="drawer"
                          />
                        ))}
                      </>
                    );
                  })}
                </Lineitems>
              </SubwrapperLineitems>

              <CartUpsells />
              <CartDeliveryOptions layout="minicart" />
              <CartNotes />

              <Subwrapper>
                <Totals layout="drawer" />
                <CartReturnsPolicy />
                <Checkout
                  href={checkoutUrl}
                  onClick={handleCheckout}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Checkout"}
                </Checkout>
                <ViewCart
                  className="block text-center"
                  onClick={handleCartActive}
                  as={cartUrl ? Link : null}
                  to={cartUrl}
                >
                  <span>View Cart</span>
                </ViewCart>
              </Subwrapper>

              {donationEnabled &&
              donationProducts &&
              donationProducts?.length > 0 ? (
                <Donationitems layout="drawer" items={donationProducts} />
              ) : null}
              <PaymentIcons>
                <StyledPaymentIcons
                  name="paypal"
                  size="none"
                  height="32"
                  width="42"
                />
                <StyledPaymentIcons
                  name="visa"
                  size="none"
                  height="32"
                  width="42"
                />
                <StyledPaymentIcons
                  name="americanExpress"
                  size="none"
                  height="32"
                  width="42"
                />
                <StyledPaymentIcons
                  name="applePay"
                  size="none"
                  height="32"
                  width="42"
                />
                <StyledPaymentIcons
                  name="mastercard"
                  size="none"
                  height="32"
                  width="42"
                />
              </PaymentIcons>
            </>
          ) : (
            <Subwrapper>
              <KeepShopping
                height="56"
                textSize="16"
                colour="primary"
                onClick={handleCartActive}
              >
                {keepShopping.title}
              </KeepShopping>
            </Subwrapper>
          )}
        </Wrapper>
      </ModalsDrawer>
    );
  },
);

export default CartDrawer;
