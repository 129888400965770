import {
  WishlistContext,
  WishlistContextValues,
  WishlistUnfilteredContext,
  WishlistUnfilteredContextValues,
} from "@providers/localised/wishlist";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { useAnalytics } from "./useAnalytics";
import { useCore } from "./useCore";
import { useCustomerContext } from "./useCustomer";
import { useHelper } from "./useHelper";
import { useKlaviyo } from "./useKlaviyo";
import { useLocalisationContext } from "./useLocalisation";
import { useShopify } from "./useShopify";
import { useFunctionsGatsby } from "./useFunctionsGatsby";

export type WishlistShareData = {
  recipientEmail?: string;
  recipientName?: string;
  message?: string;
};

export type UseWishlistShare = {
  error?: string;
  loading: boolean;
  shareWishlist: (data: WishlistShareData) => void;
  success: boolean;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
};

const allowed = ["handle", "tags", "title"];

const filterData = (allowed, data) =>
  Object.keys(data)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

const formatData = (data) => ({
  ...filterData(allowed, data),
});

export const useWishlistContext = () => {
  const wishlistData: WishlistContextValues = useContext(WishlistContext);
  return { ...wishlistData };
};

export const useToggleWishlistProduct = () => {
  const { wishlist, setWishlist } = useWishlistContext();
  const { contextCountry } = useLocalisationContext();
  const {
    graphql: {
      queries: { GET_PRODUCT },
    },
  } = useCore();

  const { productNormaliser, client } = useShopify();

  const { track } = useKlaviyo();
  const { trackWishlistUpdate } = useAnalytics();

  const addOrReplaceWishlistItem = (wishlist, stub, type) => {
    if (type === "replace") {
      const index = wishlist.findIndex((item) => item.handle === stub.handle);
      if (index >= 0) {
        const newWishlist = [...wishlist];
        newWishlist.splice(index, 1, stub);
        return newWishlist;
      }
    }

    return [...wishlist, stub];
  };

  const addToWishlist = useCallback(
    async (product, type = null) => {
      if (!product) return;

      try {
        const stub = {
          handle: product.handle,
          ...(product.selectedId ? { selectedId: product.selectedId } : {}),
        };

        setWishlist((prevState) =>
          addOrReplaceWishlistItem(prevState, stub, type),
        );

        const { data } = await client.query({
          query: GET_PRODUCT,
          variables: {
            countryCode: contextCountry,
            handle: product?.handle,
            parentQuery: `tag:'colours:${product?.handle}'`,
            firstMedia: 0,
            firstImages: 0,
            firstVariants: 100,
            firstMetafields: 0,
            firstCollections: 0,
          },
        });
        const liveProduct = productNormaliser(data?.product);
        const selectedProductVariant = liveProduct?.variants?.find(
          ({ id }) => id === product?.selectedVariant?.id,
        );
        const selectedVariant =
          selectedProductVariant || product?.variants?.[0];
        const mappedData = formatData(product);
        const size = selectedProductVariant?.selectedOptions?.find(
          (item) => item.name?.toLowerCase() === "size",
        )?.value;
        const wishlistItem = {
          ...mappedData,
          selectedId: selectedVariant?.id ?? product?.variants?.[0]?.id,
          selectedTitle:
            selectedVariant?.title ?? product?.variants?.[0]?.title,
          wishlistPrice:
            selectedVariant?.priceV2?.amount ??
            product?.variants?.[0]?.priceV2?.amount ??
            product?.variants?.[0]?.price ??
            "",
          wishlistImage: product?.images?.[0]?.src ?? "",
        };

        setWishlist((prevState) =>
          addOrReplaceWishlistItem(prevState, wishlistItem, "replace"),
        );

        if (type !== "replace") {
          trackWishlistUpdate("add", { ...product, selectedVariant });
        }

        track("Wishlist Add", {
          title: product.title,
          images: product.images,
          priceRange: product.priceRange,
          tags: product.tags,
          variants: product.variants,
          vendor: product.vendor,
          handle: product.handle,
          ...(size !== undefined && { size }),
        });
      } catch (ex) {
        console.error(ex);
      }
    },
    [setWishlist, formatData],
  );

  const deleteFromWishlist = useCallback(
    async (handle) => {
      const item = wishlist?.find((item) => item?.handle === handle);
      setWishlist((prevState) =>
        prevState.filter((item) => item?.handle !== handle),
      );

      try {
        const { data } = await client.query({
          query: GET_PRODUCT,
          variables: {
            countryCode: contextCountry,
            handle,
            parentQuery: `tag:'colours:${handle}'`,
            firstMedia: 0,
            firstImages: 0,
            firstVariants: 100,
            firstMetafields: 0,
            firstCollections: 0,
          },
        });
        const product = productNormaliser(data?.product);
        const selectedVariant = product?.variants?.find(
          ({ id }) => id === item.selectedId,
        );
        const size = selectedVariant?.selectedOptions?.find(
          (item) => item.name?.toLowerCase() === "size",
        )?.value;

        trackWishlistUpdate("remove", { ...product, selectedVariant });
        track("Wishlist Remove", {
          title: product.title,
          images: product.images,
          priceRange: product.priceRange,
          tags: product.tags,
          variants: product.variants,
          vendor: product.vendor,
          handle: product.handle,
          size,
        });
      } catch (ex) {
        console.error(ex);
      }
    },
    [setWishlist, wishlist],
  );
  return { addToWishlist, deleteFromWishlist };
};

export const useExistsInWishlist = () => {
  const { wishlist } = useWishlistContext();
  const existsInWishlist = useCallback(
    (handle) => wishlist?.some((item) => item?.handle === handle),
    [wishlist],
  );
  return { existsInWishlist };
};

export const useWishlistUnfilteredContext = () => {
  const values: WishlistUnfilteredContextValues = useContext(
    WishlistUnfilteredContext,
  );
  return { ...values };
};

export const useWishlistShare = (): UseWishlistShare => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { shareWishlistByEmail } = useFunctionsGatsby();
  const [error, setError] = useState<string>();
  const { customer } = useCustomerContext();
  const { getShareWishlistURL } = useHelper();
  const { wishlist } = useWishlistUnfilteredContext();

  const shareWishlist = useCallback(
    async (data) => {
      setLoading(true);
      setSuccess(true);

      try {
        const sharedUrl = getShareWishlistURL(
          customer?.email,
          customer?.firstName,
        );

        const sender = {
          name: customer?.firstName || customer?.lastName || customer?.email,
        };
        const recipient = {
          name: data.recipientName,
          email: data.recipientEmail,
        };
        const share = {
          message: data.message,
          url: sharedUrl,
          items: wishlist,
        };

        const result = await shareWishlistByEmail(customer?.email, {
          sender,
          recipient,
          share,
        });
        if (result.status === "success") {
          setSuccess(true);
          setError(undefined);
        } else {
          setSuccess(false);
          return setError(
            "An error occurred while sharing your wishlist, please try again",
          );
        }
      } catch (e) {
        console.error(e);
        setSuccess(false);
        setError(
          "An error occurred while sharing your wishlist, please try again",
        );
      } finally {
        setLoading(false);
        setSuccess(true);
      }
    },
    [
      getShareWishlistURL,
      shareWishlistByEmail,
      setLoading,
      setSuccess,
      setError,
    ],
  );

  return {
    error,
    loading,
    setError,
    setSuccess,
    shareWishlist,
    success,
  };
};
