import React from "react";

export const withCartLineItemPrice =
  (Component) =>
  ({
    name = "CartLineItemPrice",
    price,
    discountAllocationsAmount,
    className,
    layout,
  }) => {
    const { amount: priceAmount, currencyCode } = price;
    const priceAfterDiscount = (
      priceAmount - discountAllocationsAmount
    ).toFixed(2);
    Component.displayName = name;
    return (
      <Component
        price={Number(priceAmount)}
        priceAfterDiscount={priceAfterDiscount}
        onSale={discountAllocationsAmount > 0}
        currencyCode={currencyCode}
        className={className}
        layout={layout}
      />
    );
  };
