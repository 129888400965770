import { useWishlistContext } from "@hooks/useWishlist";
import { CustomerContext } from "@providers/global/customer";
import { useCallback, useContext, useEffect, useState } from "react";
import { useApp } from "./useApp";
import { useCheckout, useCheckoutContext } from "./useCheckout";
import { useCore } from "./useCore";
import { useKlaviyo } from "./useKlaviyo";
import { useLocalisationContext } from "./useLocalisation";
import { useShopify } from "./useShopify";
import { v4 as uuid } from "uuid";

import { useSessionCustomer, useLogout } from "./useSession";

/**
 * Customer from session.
 */
export const useCustomer = useSessionCustomer;

export const useCustomerContext = () => {
  const customerData: any = useContext(CustomerContext);
  return { ...customerData };
};

export const useCustomerSession = () => {
  const { setSessionId } = useCustomerContext();

  const {
    helpers: { storage },
  } = useCore();

  const createSessionId = (): string => {
    const existingSessionId = getSessionId();
    if (existingSessionId) {
      setSessionId(existingSessionId);
      return existingSessionId;
    }

    const sessionId = uuid();
    storage.set("session-id", sessionId);
    setSessionId(sessionId);
    return sessionId;
  };

  const getSessionId = (): string => {
    const existingSessionId = storage.get("session-id");
    return existingSessionId;
  };

  return {
    createSessionId,
    getSessionId,
  };
};

export const useCustomerAccessToken = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore();

  const {
    config: {
      settings: { keys },
    },
  } = useApp();

  const { setCustomer } = useCustomerContext();

  const { updateCustomer } = useCheckout();

  const { useMutation, useQuery } = useShopify();

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE);
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  });

  const getCustomer = useCallback(async () => {
    const customerTokens = storage.get(keys?.customer);

    if (customerTokens?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: customerTokens?.accessToken,
        });

        if (!customerUserErrors?.length && customer !== null) {
          setCustomer(customer);
        } else {
          setCustomer(null);
          storage.remove(keys?.customer);
        }
      } catch (err) {
        setCustomer(null);
        console.error(err);
      }
    } else {
      setCustomer(null);
    }
  }, [getCustomerQuery, setCustomer, keys, storage]);

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: {
              customerAccessToken,
              customerUserErrors,
            },
          },
        } = await customerAccessTokenCreate({
          variables: { input: { email, password } },
        });

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken;
          storage.set(keys?.customer, { accessToken, expiresAt });
          updateCustomer(accessToken);
          getCustomer();
        }

        return { customerAccessToken, customerUserErrors };
      } catch (err) {
        console.error(err);
        return {
          customerUserErrors: [
            {
              message: err.message,
            },
          ],
        };
      }
    },
    [customerAccessTokenCreate, updateCustomer, getCustomer],
  );

  return { createAccessToken, getCustomer };
};

export const useCustomerLogout = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CHECKOUT_CUSTOMER_DISASSOCIATE },
    },
  } = useCore();

  const {
    config: {
      settings: { keys },
    },
  } = useApp();

  const logout = useLogout();

  const { track } = useKlaviyo();

  const { setCustomer } = useCustomerContext();
  const { setCheckout } = useCheckoutContext();
  const { contextCountry } = useLocalisationContext();

  const { setWishlist, setIsWishlistInitialized } = useWishlistContext();

  const checkoutId = storage.get(keys?.checkout);

  const { useMutation, checkoutNormaliser } = useShopify();

  const [checkoutCustomerDisassociate] = useMutation(
    CHECKOUT_CUSTOMER_DISASSOCIATE,
  );

  const logoutCustomer = useCallback(async () => {
    // Logout of server-side session.
    await logout();

    const {
      data: { checkoutCustomerDisassociateV2: data },
    } = await checkoutCustomerDisassociate({
      variables: {
        countryCode: contextCountry,
        checkoutId,
      },
    });

    setCheckout(checkoutNormaliser(data?.checkout));

    storage.remove(keys?.customer);
    storage.set(keys?.checkout, data?.checkout?.id);

    track("Account Logout", false);

    setCustomer(null);

    setWishlist([]);
    setIsWishlistInitialized(false);

    /**
     * Do a hard navigate instead of a Gatsby navigate, as we want the new session cookies
     * to be used for the SSR request.
     */
    window.location.href = "/account/login";
  }, [
    checkoutCustomerDisassociate,
    setCheckout,
    setCustomer,
    keys,
    storage,
    logout,
  ]);

  return { logoutCustomer };
};

export const useCustomerOrders = (first) => {
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore();
  const {
    config: {
      settings: { keys },
    },
  } = useApp();
  const { useQuery } = useShopify();
  const { contextCountry } = useLocalisationContext();
  const { accessToken: customerAccessToken } = storage.get(keys?.customer);

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      countryCode: contextCountry,
      customerAccessToken,
      first,
      reverse: true,
    },
  });
  const orders = data?.customer?.orders?.edges?.filter(
    ({ node }) =>
      ["PAID", "PARTIALLY_REFUNDED"].includes(node?.financialStatus) &&
      node?.currentTotalPrice?.amount !== "0.0",
  );
  return { orders, loading, error };
};

export const useCustomerAddress = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: {
        CUSTOMER_ADDRESS_CREATE,
        CUSTOMER_ADDRESS_UPDATE,
        CUSTOMER_ADDRESS_DELETE,
        CUSTOMER_DEFAULT_ADDRESS_UPDATE,
      },
      queries: { GET_CUSTOMER },
    },
  } = useCore();
  const {
    config: {
      settings: { keys },
    },
    setNewAddressId,
  } = useApp();
  const { useMutation, useLazyQuery } = useShopify();
  const { track } = useKlaviyo();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const initialData = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    province: "",
    zip: "",
  };
  const [address, setAddress] = useState({
    ...initialData,
    id: "",
    action: "",
  });
  const [addresses, setAddresses] = useState([]);
  const { accessToken: customerAccessToken } =
    storage.get(keys?.customer) || {};

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE);
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE);
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE);
  const [customerDefaultAddressUpdate] = useMutation(
    CUSTOMER_DEFAULT_ADDRESS_UPDATE,
  );

  const filterData = (address) =>
    Object.keys(address)
      .filter((key) => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = address[key];
        return obj;
      }, {});

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
    },
  });

  useEffect(() => {
    getAll();
  }, [saving]);

  useEffect(() => {
    if (data?.customer)
      setAddresses(
        data?.customer?.addresses?.edges?.map(({ node }) => ({
          ...node,
          default: node?.id === data?.customer?.defaultAddress?.id,
        })),
      );
  }, [data]);

  const createAddress = useCallback(
    async (address) => {
      setSaving(true);
      setErrors([]);

      try {
        const {
          data: {
            customerAddressCreate: { customerUserErrors, customerAddress },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        });

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" });
          setNewAddressId(customerAddress.id);
          setSaving(false);
          track("Address Add", filterData(address));
        } else {
          setErrors(customerUserErrors);
          setSaving(false);
        }
      } catch (err) {
        console.error(err);
        setErrors([err]);
        setSaving(false);
      }
    },
    [
      setSaving,
      setErrors,
      setAddress,
      setNewAddressId,
      customerAddressCreate,
      filterData,
      initialData,
    ],
  );

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true);
      setErrors([]);

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        });

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" });
          setSaving(false);
          track("Address Update", filterData(address));
        } else {
          setErrors(customerUserErrors);
          setSaving(false);
        }
      } catch (err) {
        console.error(err);
        setErrors([err]);
        setSaving(false);
      }
    },
    [
      setSaving,
      setErrors,
      setAddress,
      customerAddressUpdate,
      filterData,
      initialData,
    ],
  );

  const defaultAddress = useCallback(
    async (addressId) => {
      setSaving(true);
      setErrors([]);

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        });

        if (!customerUserErrors?.length) {
          setSaving(false);
          track("Address Default", addressId);
        } else {
          setErrors(customerUserErrors);
          setSaving(false);
        }
      } catch (err) {
        console.error(err);
        setErrors([err]);
        setSaving(false);
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate],
  );

  const deleteAddress = useCallback(
    async (id) => {
      setSaving(true);
      setErrors([]);

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        });

        if (!customerUserErrors?.length) {
          setSaving(false);
          track("Address Delete", id);
        } else {
          setErrors(customerUserErrors);
          setSaving(false);
        }
      } catch (err) {
        console.error(err);
        setErrors([err]);
        setSaving(false);
      }
    },
    [setSaving, setErrors, customerAddressDelete],
  );

  return {
    addresses,
    setAddress,
    address,
    createAddress,
    updateAddress,
    defaultAddress,
    deleteAddress,
    filterData,
    initialData,
    loading,
    saving,
    errors,
  };
};

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_UPDATE },
    },
  } = useCore();
  const {
    config: {
      settings: { keys },
    },
  } = useApp();
  const { useMutation } = useShopify();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    acceptsMarketing: false,
  };
  const [customerUpdate] = useMutation(CUSTOMER_UPDATE);
  const { customer: initialCustomer, setCustomer: saveCustomer } =
    useCustomerContext();
  const [customer, setCustomer] = useState(initialCustomer);
  const { accessToken: customerAccessToken } = storage.get(keys?.customer) || {
    accessToken: "",
  };

  const filterData = (data, hidePassword = false) => {
    return hidePassword
      ? Object.keys(data)
          .filter((key) => Object.keys(initialData).includes(key))
          .filter((key) => key !== "password")
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {})
      : Object.keys(data)
          .filter((key) => Object.keys(initialData).includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
  };

  const updateCustomer = useCallback(
    async (customer) => {
      setSaving(true);
      setErrors([]);

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(customer) },
        });

        if (!customerUserErrors?.length) {
          saveCustomer((prevCustomer) => ({
            ...prevCustomer,
            ...filterData(customer, true),
          }));
          setSaving(false);
        } else {
          setErrors(customerUserErrors);
          setSaving(false);
        }
      } catch (err) {
        console.error(err);
        setErrors([err]);
        setSaving(false);
      }
    },
    [setSaving, setErrors, setCustomer, customerUpdate, filterData],
  );

  return { customer, setCustomer, updateCustomer, saving, errors };
};
