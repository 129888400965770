import { withFocusRing } from "@components/Accessibility/FocusRing/FocusRing";
import { ContentDivider } from "@components/ContentDivider/ContentDivider";
import BasePlaceAutocomplete from "@components/Form/FormPlaceAutocomplete";
import { Icon } from "@components/Icon";
import Money from "@components/Money/Money";
import { StyledH4, StyledH5 } from "@components/Styled/Text";
import React from "react";
import tw, { styled } from "twin.macro";
import {
  DeliveryOptionsOutputProps,
  withDeliveryOptions,
} from "./withDeliveryOptions";

const Wrapper = tw.div`
  w-full px-2
`;
const Content = tw.section`
  w-full text-caption text-black flex flex-col md:text-body
`;
const ErrorContent = tw.section`
  w-full text-body text-black flex flex-col
`;
const Row = tw.div`
  flex flex-row items-start pb-1
`;
const Header = tw.header`
  flex flex-wrap w-full
`;
const ChangeAddress = tw.div`
  flex order-3 w-full md:w-1/2 md:order-2 md:justify-end
`;
const YourLocation = tw.div`
  flex order-1 w-full md:w-1/2
`;
const Label = tw(StyledH5)`flex leading-input text-primary`;
const FormPlaceAutocomplete = styled(BasePlaceAutocomplete)`
  ${tw`border`}
`;
const GeolocationButton = withFocusRing(tw.button`
  text-hint mt-midi flex items-start
`);
const ErrorMessageSmall = tw.div`
  text-warning
`;
const Address = tw(StyledH4)`
  flex order-2 w-full font-normal mb-midi mt-mini text-primary text-body md:order-3 md:mb-0
`;
const ChangeAddressButton = withFocusRing(tw.button`
  text-hint flex items-start leading-input pb-[1px] md:pb-0
`);
const UnderlineText = tw.span`text-primary border-b pb-nano block`;
const SmallIcon = tw(Icon)`w-midi h-midi text-primary`;
const Divider = styled(ContentDivider)`
  ${tw`w-auto my-3 -mx-4 text-neutral`}
`;
const OptionsContainer = tw.section`
`;
const Options = tw.section`
  w-full mt-1
`;
const OptionsButton = withFocusRing(styled.button`
  ${tw`w-full border cursor-pointer p-midi mb-mini border-quaternary`}
  ${({ selected }) => (selected ? tw`bg-tertiary` : null)}
`);
const OptionsRow = tw.div`
  flex justify-between
`;
const OptionsColumnPrice = tw.div`w-mega text-right text-primary align-top md:w-10`;
const OptionsHeader = tw.header``;
const OptionsHeading = tw(StyledH5)`
  font-medium tracking-body text-left text-primary normal-case text-body leading-input my-nano
`;
const OptionsSubHeading = tw.span`
  font-normal text-hint
`;
const OptionsContent = styled.div`
  ${tw`mt-mini text-left text-caption text-primary`}
  a {
    ${tw`border-b pb-[1px]`}
  }
  b,
  strong {
    ${tw`font-medium`}
  }
`;
const Price = styled(Money)`
  ${tw`font-medium`}
  ${({ price }) => (price?.amount === "0.0" ? tw`uppercase text-secondary` : null)}}
`;
const LoadingContainer = tw.div`
  flex justify-center p-2
`;
const ErrorMessage = tw.p`
  text-hint text-warning leading-h4 mt-mini md:text-body
`;

const DeliveryOptions = withDeliveryOptions(
  ({
    address,
    changeLocationLabel,
    geolocationErrorMessage,
    googlePlacesErrorMessage,
    hasGeolocationError,
    hasGooglePlacesError,
    hasResults,
    inputRef,
    isShippingErrorActive,
    layout,
    loading,
    loadingLabel,
    locationButtonLabel,
    noShippingOptionsErrorMessage,
    onAddressSelect,
    onAddressChange,
    onChangeAddress,
    onGeolocationClick,
    onOptionSelect,
    readOnly,
    shippingRates,
    suggestions,
    suggestionsLabel,
    textInputLabel,
    yourDeliveryOptionsLabel,
    yourLocationLabel,
    value,
  }: DeliveryOptionsOutputProps) => {
    return (
      <Wrapper>
        <Content>
          {!hasResults && (
            <>
              <Row>
                <SmallIcon name="map-pin" size="small" />
                <Label as="label">{textInputLabel}</Label>
              </Row>
              <Row>
                <FormPlaceAutocomplete
                  bgColour={layout === "minicart" ? "beige" : "light"}
                  inputRef={inputRef}
                  loading={loading}
                  loadingLabel={loadingLabel}
                  onSelect={onAddressSelect}
                  onChange={onAddressChange}
                  readOnly={readOnly}
                  suggestions={suggestions}
                  suggestionsLabel={suggestionsLabel}
                  value={value}
                />
              </Row>
              <Row>
                <GeolocationButton
                  data-testid="geolocation"
                  onClick={onGeolocationClick}
                >
                  <SmallIcon name="location" size="small" />
                  <UnderlineText>{locationButtonLabel}</UnderlineText>
                </GeolocationButton>
              </Row>
              {(hasGeolocationError || hasGooglePlacesError) && (
                <Row>
                  {hasGeolocationError && (
                    <ErrorMessageSmall>
                      {geolocationErrorMessage}
                    </ErrorMessageSmall>
                  )}
                  {hasGooglePlacesError && (
                    <ErrorMessageSmall>
                      {googlePlacesErrorMessage}
                    </ErrorMessageSmall>
                  )}
                </Row>
              )}
            </>
          )}
          {hasResults && (
            <>
              <Header>
                <YourLocation>
                  <SmallIcon name="map-pin" size="small" />
                  <Label as="label">{yourLocationLabel}</Label>
                </YourLocation>
                <ChangeAddress>
                  <ChangeAddressButton onClick={onChangeAddress}>
                    <UnderlineText>{changeLocationLabel}</UnderlineText>
                  </ChangeAddressButton>
                </ChangeAddress>
                <Address>{address?.formatted_address}</Address>
              </Header>
              <Divider colour={layout == "cart" ? "light" : "beige"} />
              <OptionsContainer>
                <Label>
                  <SmallIcon name="clock" size="small" />
                  {yourDeliveryOptionsLabel}
                </Label>
                {loading && (
                  <LoadingContainer>
                    <Icon spin={true} name="spinner" size="xsmall" />
                  </LoadingContainer>
                )}
                {!loading && (
                  <Options>
                    {shippingRates?.map(
                      ({
                        handle,
                        name,
                        content,
                        price,
                        deliveryTimeEstimate,
                        selected,
                        index,
                      }) => (
                        <OptionsButton
                          key={index}
                          onClick={onOptionSelect(handle)}
                          selected={selected}
                        >
                          <OptionsRow>
                            <OptionsHeader>
                              <OptionsHeading>
                                {name}
                                {deliveryTimeEstimate && (
                                  <OptionsSubHeading>
                                    {" "}
                                    | {deliveryTimeEstimate?.timeEstimate}
                                  </OptionsSubHeading>
                                )}
                              </OptionsHeading>
                              <OptionsContent>{content}</OptionsContent>
                            </OptionsHeader>
                            <OptionsColumnPrice>
                              <Price price={price} displayZeroAsFree={true} />
                            </OptionsColumnPrice>
                          </OptionsRow>
                        </OptionsButton>
                      ),
                    )}
                    {isShippingErrorActive && (
                      <ErrorContent>
                        <ErrorMessage>
                          {noShippingOptionsErrorMessage}
                        </ErrorMessage>
                      </ErrorContent>
                    )}
                  </Options>
                )}
              </OptionsContainer>
            </>
          )}
        </Content>
      </Wrapper>
    );
  },
);

export default DeliveryOptions;
