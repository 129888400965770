import React, { useCallback } from "react";

import {
  UIProvider as _UIProvider,
  UIProviderProps as _UIProviderProps,
} from "@bared/ui";

import { useSite } from "~/hooks/useSite";
import { useSanityClient } from "~/hooks/sanity";

import { Link } from "~/components/Link";

type HandleTrackingEvent = NonNullable<_UIProviderProps["onTrackingEvent"]>;

export interface UIProviderProps {
  children: React.ReactNode;
}

export function UIProvider({ children }: UIProviderProps) {
  const site = useSite();

  /** Handle tracking events emitted by the UI lib. */
  const handleTrackingEvent = useCallback<HandleTrackingEvent>((event) => {
    // @todo send to gtm
  }, []);

  /** Sanity client resolved from context. */
  const sanityClient = useSanityClient();

  return (
    <_UIProvider
      locale={site.locale}
      sanityClient={sanityClient}
      onTrackingEvent={handleTrackingEvent}
      renderLink={(props) => <Link {...props} />}
    >
      {children}
    </_UIProvider>
  );
}

UIProvider.displayName = "UIProvider";
