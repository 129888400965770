import { useAtomValue } from "jotai";

import {
  loginAtom,
  logoutAtom,
  sessionAtom,
  registerAtom,
  activateAtom,
  sessionIdAtom,
  resetPasswordAtom,
  sessionStatusAtom,
  forgotPasswordAtom,
  sessionCustomerAtom,
} from "~/atoms/session";

import { getLegacyLocalStorageItem } from "~/lib/storage.client";

/**
 * Response from /api/session endpoint.
 */
export function useSession() {
  return useAtomValue(sessionAtom);
}

/**
 * Status of session.
 */
export function useSessionStatus() {
  return useAtomValue(sessionStatusAtom);
}

/**
 * Customer from session.
 */
export function useSessionCustomer() {
  return useAtomValue(sessionCustomerAtom);
}

/**
 * Return legacy access token from local storage.
 */
export function useLegacyCustomerAccessToken() {
  const item = getLegacyLocalStorageItem("headless:customer");
  const accessToken = item?.accessToken as string | undefined;
  return accessToken ?? null;
}

/**
 * Return true if the customer is logged in, false if not,
 * and undefined if session is still loading.
 */
export function useSessionLoggedIn() {
  const status = useSessionStatus();
  const customer = useSessionCustomer();
  const legacyCustomerAccessToken = useLegacyCustomerAccessToken();

  if ("pending" === status) return undefined;

  return !!customer && !!legacyCustomerAccessToken;
}

/**
 * Session ID from session.
 */
export function useSessionId() {
  return useAtomValue(sessionIdAtom);
}

/**
 * Login a session.
 */
export function useLogin() {
  const { mutateAsync } = useAtomValue(loginAtom);
  return mutateAsync;
}

/**
 * Logout the current session.
 */
export function useLogout() {
  const { mutateAsync } = useAtomValue(logoutAtom);
  return mutateAsync;
}

/**
 * Register a new account.
 */
export function useRegister() {
  const { mutateAsync } = useAtomValue(registerAtom);
  return mutateAsync;
}

/**
 * Initiate a "forgot password" request.
 */
export function useForgotPassword() {
  const { mutateAsync } = useAtomValue(forgotPasswordAtom);
  return mutateAsync;
}

/**
 * Reset password.
 */
export function useResetPassword() {
  const { mutateAsync } = useAtomValue(resetPasswordAtom);
  return mutateAsync;
}

/**
 * Activate a customer account.
 */
export function useActivate() {
  const { mutateAsync } = useAtomValue(activateAtom);
  return mutateAsync;
}
