import { type CustomerSmsMarketingConsentState } from "@ts/shopify-admin";
import {
  type Customer,
  type Metafield,
  type UserError,
} from "@ts/shopify-storefront";
import { useCallback, useState } from "react";
import { useApp } from "./useApp";
import { useCheckoutContext } from "./useCheckout";
import { useCore } from "./useCore";
import { useCustomerContext } from "./useCustomer";
import { useLocalisationContext } from "./useLocalisation";
import { useLocation } from "./useLocation";

type FunctionResponse<T = unknown> = {
  status?: "success" | "error";
  body?: string | T;
};

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore();
  const { shopifyStore, country } = useLocation();
  const {
    config: {
      services: { functions },
      settings: { keys },
      stores,
    },
  } = useApp();
  const { currentLocale } = useLocalisationContext();
  const { defaultCountryCode } = currentLocale || {};

  const { customer, setCustomer } = useCustomerContext();
  const { checkout } = useCheckoutContext();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<UserError[] | string[]>([]);
  const dataset = stores[shopifyStore]?.sanityDataset;

  const request = useCallback(
    async (endpoint, data, overrides: RequestInit = {}) => {
      setLoading(true);
      setErrors([]);

      const local =
        "development" === process.env.NODE_ENV &&
        process.env.GATSBY_CLOUD_PREVIEW !== "1";

      return fetch(
        `${local ? "/api" : functions?.endpoint}${endpoint}?shop=${shopifyStore}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          ...overrides,
          ...(overrides.method !== "GET" && { body: JSON.stringify(data) }),
        },
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          return result;
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setErrors([error]);
          return error;
        });
    },
    [setLoading, setErrors, fetch, shopifyStore],
  );

  const addCountryTag = (tags: string[]): string[] => {
    const countryTag = `${keys?.country_klavio_tag}:${country}`;
    const regex = new RegExp(keys?.country_klavio_tag);
    return !regex.test(tags?.join()) ? [...tags, countryTag] : tags;
  };

  const customerSubscribe = useCallback<
    (
      email: string,
      tagsList: string[],
      acceptsMarketing: boolean,
      smsMarketingConsent: CustomerSmsMarketingConsentState,
      customerId?: string,
      customerEmail?: string,
      phone?: string,
    ) => Promise<{
      customer: Customer;
      userErrors: UserError[];
    }>
  >(
    async (
      email,
      tagsList,
      acceptsMarketing,
      smsMarketingConsent,
      customerId,
      customerEmail,
      phone,
    ) => {
      const listOfTags = addCountryTag(tagsList);
      const tags = [...listOfTags, `language:${defaultCountryCode}`];
      const { customer, userErrors } = await request("/customer/subscribe", {
        email,
        tags,
        customerId,
        customerEmail,
        phone,
        acceptsMarketing,
        smsMarketingConsent,
      });
      return { customer, userErrors };
    },
    [request],
  );

  const customerTags = useCallback<
    (customerId: string, tagsList: string[]) => Promise<void>
  >(
    async (customerId, tagsList) => {
      const id = decodeBase64(customerId || customer?.id);
      const listOfTags = addCountryTag(tagsList);
      const tags = [...listOfTags, `language:${defaultCountryCode}`];
      const { userErrors } = await request("/customer/tags", { id, tags });
      if (!userErrors?.length)
        setCustomer((prevState) => ({ ...prevState, tags }));
    },
    [customer, decodeBase64, request, setCustomer],
  );

  const returnsSubmit = useCallback<
    <T, K>(data: T) => Promise<FunctionResponse<K>>
  >(
    async (data) => {
      const { status, body } = await request("/returns/submit", { data });
      return { status, body };
    },
    [request],
  );

  const checkoutMultipass = useCallback<
    (customerEmail: string, checkoutId: string, webUrl: string) => Promise<void>
  >(
    async (customerEmail, checkoutId, webUrl) => {
      try {
        if (customerEmail) {
          const response = await request(
            "/checkout/multipass",
            { customerEmail, checkoutId, webUrl },
            { redirect: "follow" },
          );
          const url =
            response?.status !== "error" && response?.includes("https://")
              ? response
              : webUrl;
          window.location.replace(url);
        } else {
          window.location.replace(webUrl);
        }
      } catch (e) {
        console.error(e);
        window.location.replace(webUrl);
      }
    },
    [customer, checkout, request],
  );

  const getCustomerMetafield = useCallback<
    (
      id: string,
      key: string,
      namespace: string,
    ) => Promise<{ metafield: Metafield }>
  >(
    async (id, key, namespace) => {
      const { metafield } = await request("/customer/get-metafield", {
        id,
        key,
        namespace,
      });
      return { metafield };
    },
    [customer, decodeBase64, request],
  );

  const updateCustomerMetafield = useCallback<
    (
      key: string,
      namespace: string,
      metafieldId: string,
      value: string,
      customerId?: string,
    ) => Promise<{ userErrors: UserError[] }>
  >(
    async (key, namespace, metafieldId, value, customerId) => {
      const id = decodeBase64(customerId || customer?.id);
      const { userErrors } = await request("/customer/update-metafield", {
        id,
        key,
        namespace,
        metafieldId,
        value,
      });
      return { userErrors };
    },
    [customer, decodeBase64, request],
  );

  const productReviews = useCallback<
    <T>(
      productGroup: string,
      page: number,
      pageSize: number,
      ratingFilter?: number,
    ) => Promise<FunctionResponse<T>>
  >(
    async (productGroup, page, pageSize, ratingFilter) => {
      const { status, body } = await request("/reviews/timeline", {
        productGroup,
        page,
        pageSize,
        ratingFilter,
      });
      return { status, body };
    },
    [request],
  );

  const productQuestions = useCallback<
    <T>(
      productIds: string[],
      page: number,
      pageSize: number,
    ) => Promise<FunctionResponse<T>>
  >(
    async (productIds, page, pageSize) => {
      const { status, body } = await request("/reviews/questions", {
        productIds,
        page,
        pageSize,
      });
      return { status, body };
    },
    [request],
  );

  const productReviewAdd = useCallback<
    <T>(
      email: string,
      name: string,
      review: string,
      title: string,
      rating: number,
      sku: string,
      size?: string,
      fitLength?: string,
      fitWidth?: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (
      email,
      name,
      review,
      title,
      rating,
      sku,
      size,
      fitLength,
      fitWidth,
    ) => {
      const reviewWithFitFeedback = {
        email,
        name,
        review,
        title,
        rating,
        sku,
        ratings: {
          "What is your usual shoe size?": size,
          "How did you find the fit length?": fitLength,
          "How did you find the fit width?": fitWidth,
        },
      };

      const reviewWithoutFitFeedback = {
        email,
        name,
        review,
        title,
        rating,
        sku,
      };

      const reviewBody = size
        ? reviewWithFitFeedback
        : reviewWithoutFitFeedback;

      const { status, body } = await request("/reviews/add", reviewBody);
      return { status, body };
    },
    [request],
  );

  const productQuestionAdd = useCallback<
    <T>(
      email: string,
      grouping_hash: string,
      name: string,
      question: string,
      url: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (email, grouping_hash, name, question, url) => {
      const { status, body } = await request("/reviews/questions/add", {
        email,
        grouping_hash,
        name,
        question,
        url,
      });
      return { status, body };
    },
    [request],
  );

  const searchSanity = useCallback<
    <T>(terms: string) => Promise<FunctionResponse<T>>
  >(
    async (terms) => {
      const { status, body } = await request("/search", {
        terms,
        store: dataset,
      });
      return { status, body };
    },
    [request],
  );

  const storageUploadFiles = useCallback<
    <T, K>(email: string, files: T) => Promise<FunctionResponse<K>>
  >(
    async (email, files) => {
      const { status, body } = await request(
        `/storage`,
        { email, files },
        { method: "POST" },
      );
      return { status, body };
    },
    [request],
  );

  return {
    addCountryTag,
    checkoutMultipass,
    customerSubscribe,
    customerTags,
    errors,
    getCustomerMetafield,
    loading,
    productQuestionAdd,
    productQuestions,
    productReviewAdd,
    productReviews,
    returnsSubmit,
    searchSanity,
    setErrors,
    updateCustomerMetafield,
    storageUploadFiles,
  };
};
