import { IconProps } from "@components/Icon/Icon";
import { useApp } from "@hooks/useApp";
import { useCustomerContext } from "@hooks/useCustomer";
import { ComponentProps } from "@ts/components";
import React, { FC, useEffect, useState } from "react";

type WidgetInputProps = ComponentProps;

type WidgetOutputProps = WidgetInputProps & {
  accountLink: string;
  iconName: Extract<IconProps, "name">;
  handleClick: () => void;
};

export const withWidget =
  (Component: FC<WidgetOutputProps>) =>
  ({ name = "Widget" }: WidgetInputProps) => {
    const {
      config: {
        settings: { routes },
      },
      globalStateReducer,
    } = useApp();

    const { customer } = useCustomerContext();
    const accountLink = routes?.DASHBOARD;
    const [iconName, setIconName] = useState("account");
    const [, dispatch] = globalStateReducer;

    const handleClick = () => {
      dispatch({ type: "navigationClicked" });
    };

    useEffect(() => {
      const isLoggedIn = customer;
      if (isLoggedIn) {
        setIconName("accountActive");
      } else {
        setIconName("account");
      }
    }, [customer]);

    Component.displayName = name;
    return (
      <Component
        accountLink={accountLink}
        handleClick={handleClick}
        iconName={iconName as Extract<IconProps, "name">}
      />
    );
  };
