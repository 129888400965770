import { useCallback } from "react";
import { useApp } from "./useApp";
import { useLocalisation } from "./useLocalisation";

export const useKlaviyo = () => {
  const {
    config: {
      app: { url },
      settings: { routes },
    },
  } = useApp();

  const { currentLocale } = useLocalisation();
  const { defaultCountryCode } = currentLocale || {};

  //@ts-ignore
  const klaviyo =
    typeof window !== "undefined" && window.klaviyo ? window.klaviyo : [];
  const isDev =
    process.env.GATSBY_ACTIVE_ENV === "development" ||
    process.env.NODE_ENV === "development";

  const cleanData = (data) => {
    try {
      const clean = JSON.parse(
        JSON.stringify(data, (k, v) =>
          ["id", "internal", "parent", "metadata"].includes(k) ||
          k.startsWith("_")
            ? undefined
            : ["image", "thumbnail"].includes(k)
              ? { alt: v?.alt || v?.altText, url: v?.asset?.url || v?.url }
              : ["slug"].includes(k)
                ? v?.current
                : [
                      "date",
                      "dateCreated",
                      "dateUpdated",
                      "publishedAt",
                    ].includes(k)
                  ? `${new Date(v).toISOString()}`
                  : [
                        "content",
                        "subcontent",
                        "additionalContent",
                        "description",
                      ].includes(k) && Array.isArray(v)
                    ? v
                        ?.map(({ children }) => children?.[0]?.text)
                        ?.join("<br/>")
                    : Array.isArray(v) && !v?.length
                      ? undefined
                      : v?.constructor === Object &&
                          Object.entries(v)?.length === 0
                        ? undefined
                        : typeof v === "boolean"
                          ? v
                          : v || undefined,
        ),
      );
      return clean;
    } catch (err) {
      console.error(err);
    }
    return;
  };

  const identify = useCallback(
    (customer) => {
      if (isDev) console.log(`[KLAVIYO] identify fired.`);
      klaviyo.push([
        "identify",
        {
          $email: `${customer?.email}`,
          $first_name: `${customer?.firstName || ""}`,
          $last_name: `${customer?.lastName || ""}`,
          // Custom Properties...
        },
      ]);
    },
    [klaviyo, isDev],
  );

  const track = useCallback(
    (type, item) => {
      if (isDev) console.log(`[KLAVIYO] track(${type}) fired.`);
      klaviyo.push([
        "track",
        type,
        {
          ...cleanData(item),
          language: defaultCountryCode,
        },
      ]);
    },
    [klaviyo, isDev],
  );

  const trackProduct = useCallback(
    (product) => {
      const { title, id, collections, images, vendor, variants, handle } =
        product;
      if (title) {
        const item = {
          Name: title,
          ProductID: id,
          Categories: collections?.map((collection) => collection.title),
          ImageURL: images?.[0]?.src,
          URL: `${url}${routes.PRODUCT}/${handle}`,
          Brand: vendor,
          Price: variants?.[0]?.priceV2?.amount,
        };
        track("Viewed Product", item);
        track("trackViewedItem", {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
          },
        });
      }
    },
    [routes],
  );

  return { identify, track, trackProduct };
};
