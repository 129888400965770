import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { UIProvider } from "./ui";
import { AppProvider } from "./app";
import { ShopProvider } from "./shop";
import { SearchProvider } from "./search";
import { ShopifyProvider } from "./shopify";
import { CheckoutProvider } from "./checkout";
import { CustomerProvider } from "./customer";
import { FirebaseProvider } from "./firebase";

import { SanityProvider } from "./SanityProvider";

import config from "../../../config.default.mjs";

const queryClient = new QueryClient();

export interface GlobalProvidersProps {
  children: React.ReactNode;
}

export const GlobalProviders: React.FC<GlobalProvidersProps> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SanityProvider>
        <AppProvider config={config}>
          <SearchProvider>
            <FirebaseProvider config={config?.services?.firebase}>
              <ShopProvider>
                <CustomerProvider>
                  <CheckoutProvider>
                    <UIProvider>
                      <ShopifyProvider>{children}</ShopifyProvider>
                    </UIProvider>
                  </CheckoutProvider>
                </CustomerProvider>
              </ShopProvider>
            </FirebaseProvider>
          </SearchProvider>
        </AppProvider>
      </SanityProvider>
    </QueryClientProvider>
  );
};
