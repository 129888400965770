import React from "react";

import { SubscribeForm, SanityRichText, RichText, Footer } from "@bared/ui";

import { useSite } from "~/hooks/useSite";
import { useRouteLoaderData } from "~/hooks/useRouteLoaderData";

import { FooterContent } from "./FooterContent";

export function FooterSection() {
  const site = useSite();
  const { settingSubscribe } = useRouteLoaderData("root");

  return (
    <Footer
      subscribeForm={
        <SubscribeForm
          siteHandle={site.handle}
          titleContent={
            <RichText>
              <h3>{settingSubscribe.emailTitle}</h3>
              <SanityRichText value={settingSubscribe.emailContent} />
            </RichText>
          }
        />
      }
    >
      <FooterContent />
    </Footer>
  );
}
