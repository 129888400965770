import React from "react";
import tw, { styled } from "twin.macro";
import { withCartLineItemPrice } from "./withCartLineItemPrice";

const Wrapper = styled.div`
  ${({ layout }) => {
    if (layout === `cart`) return tw`flex flex-row`;
    else return tw`flex flex-col justify-end`;
  }}
`;

const Money = styled.span`
  ${({ sale }) => sale && tw`text-primary font-medium`}
  ${({ layout }) => {
    if (layout === `product`) return tw`mt-pico`;
    if (layout === `cart`) return tw`ml-pico`;
    else return tw`mt-nano`;
  }}
`;

export const CartLineItemPrice = withCartLineItemPrice(
  ({ onSale, currencyCode, price, priceAfterDiscount, className, layout }) => (
    <div className={className}>
      {onSale ? (
        <Wrapper layout={layout}>
          <s>
            {currencyCode}${price}
          </s>
          <Money sale={onSale} layout={layout}>
            {currencyCode}${priceAfterDiscount}
          </Money>
        </Wrapper>
      ) : (
        <Money>
          {currencyCode}${price}
        </Money>
      )}
    </div>
  ),
);
