import { HeadlessCore } from "@dotdev/headless-core-app";

/**
 * Return a legacy local storage item which was set using one of the @dotdev utilities.
 *
 * These local storage items are stored using the "store" npm package, which is
 * ancient, and we want to avoid bringing directly into our project.
 *
 * This method carefully unpacks the local storage item with the given key.
 *
 * The value could be anything. Some keys are stored as string, some are objects etc.
 *
 * @deprecated Do not use this for anything new, only for fetching legacy local
 * storage items for the purpose of migration. Instead, you should use the
 * browser local storage API directly.
 */
export function getLegacyLocalStorageItem(key: string) {
  return HeadlessCore.helpers.storage.get(key);
}

/**
 * Set a local storage item which can be read using one of the @dotdev utilities.
 * @deprecated Do not use this for anything new. Use the browser local storage API directly.
 */
export function setLegacyLocalStorageItem(key: string, value: any): void {
  HeadlessCore.helpers.storage.set(key, value);
}
