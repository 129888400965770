import { useApp } from "@hooks/useApp";
import { useCart } from "@hooks/useCart";
import { useProductContext } from "@hooks/useProduct";
import { ComponentProps } from "@ts/components";
import {
  CheckoutLineItem,
  MoneyV2,
  SelectedOption,
} from "@ts/shopify-storefront";
import React, { FC, useEffect } from "react";

export type CartLineitemCardInputProps = ComponentProps & {
  isLast: boolean;
  layout: "cart" | "drawer";
  lineItem: CheckoutLineItem;
};

export type CartLineItemCardOutputProps = Omit<
  CartLineitemCardInputProps,
  "lineItem"
> & {
  handleLineItemRemove: () => void;
  handleSelect: (value: string) => void;
  lineItem: Partial<CheckoutLineItem> & {
    discountAllocationsAmount: number;
    handle: string;
    image?: string;
    isGiftCard: boolean;
    isPreOrder: boolean;
    link: string;
    price: MoneyV2;
    selectedOptions: SelectedOption;
    subTitle: string;
  };
  loading: boolean;
  onUpdateQuantity: (qty: number) => () => void;
  options: any;
  singleVariant: boolean;
};

export const withCartLineItemCard =
  (Component: FC<CartLineItemCardOutputProps>) =>
  ({
    name = "CartLineitemCard",
    lineItem,
    className,
    layout,
    isLast,
    ...rest
  }: CartLineitemCardInputProps) => {
    const {
      config: {
        settings: { keys, routes },
      },
    } = useApp();
    const {
      loading,
      removeLineItemFromCart,
      setLoading,
      updateQuantity,
      updateVariant,
    } = useCart();
    const {
      product,
      dropdownOptions,
      handleOptionChange,
      selectedVariant,
      selectedVariantAccessories,
    } = useProductContext();
    const { variants } = product;
    const singleVariant = variants?.length === 1;
    const title = lineItem.title.split(" ")[0];

    const thumbnailOverride = lineItem?.customAttributes?.find(
      (attr) => attr.key === "_image",
    );
    const isGiftCard = product?.tags?.includes("type:giftcard");
    const price = lineItem.variant.priceV2;

    const mappedLineitem = {
      id: lineItem.variant?.id,
      title: title,
      subTitle: lineItem.title?.replace(title, ``),
      quantity: lineItem.quantity,
      price,
      discountAllocationsAmount:
        lineItem.discountAllocations.reduce(
          (acc, cur) => acc + Number(cur.allocatedAmount.amount),
          0,
        ) / lineItem.quantity,
      compareAtPrice: lineItem.variant?.compareAtPriceV2,
      image: thumbnailOverride?.value || lineItem.variant?.image?.url,
      link: `${routes.PRODUCT}/${lineItem.variant?.product?.handle}`,
      selectedOptions: lineItem.variant?.selectedOptions.filter(
        (option) => option.name.toLowerCase() === "size",
      )[0],
      handle: lineItem.variant?.product?.handle,
      isPreOrder: !!lineItem?.customAttributes?.filter(
        (attr) => attr.key === keys?.cart_preorder && attr.value === "Yes",
      )?.length,
      isGiftCard,
    };

    const handleSizeChange = async () => {
      if (!selectedVariant?.id) {
        return;
      }

      try {
        await updateVariant(
          mappedLineitem.id,
          selectedVariant.id,
          selectedVariantAccessories,
        );
      } finally {
        setLoading(false);
      }
    };

    const handleLineItemRemove = async () => {
      try {
        await removeLineItemFromCart(lineItem.id);
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateQuantity = (qty: number) => async () => {
      try {
        await updateQuantity(
          mappedLineitem?.id,
          mappedLineitem?.quantity + qty,
        );
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      handleSizeChange();
    }, [selectedVariant, selectedVariantAccessories]);

    Component.displayName = name;
    return (
      <Component
        className={className}
        handleLineItemRemove={handleLineItemRemove}
        handleSelect={handleOptionChange}
        isLast={isLast}
        layout={layout}
        lineItem={mappedLineitem}
        loading={loading}
        onUpdateQuantity={handleUpdateQuantity}
        options={dropdownOptions}
        singleVariant={singleVariant}
        {...rest}
      />
    );
  };
